/************************** Full screen ********************** */
@media (min-width: 1024px) {
  .hospitality {
    min-height: 90vh;
    display: flex;
    padding: 7.5rem 0rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: black;
    gap: 5rem;
  }

  .hospitality h2 {
    color: #0f1533;
    text-align: center;
    font-family: Gilroy-SemiBold;
    font-size: 2.625rem;
    font-style: normal;
    font-weight: 400;
    line-height: 3.5rem;
    letter-spacing: 0.0525rem;
    color: white;
  }

  .hospitality span {
    color: #0f1533;
    font-family: Caveat;
    font-size: 3.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: 3.5rem;
    letter-spacing: 0.065rem;
    color: white;
  }

  .hospi-imgs {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.25rem;
    padding-bottom: 2.5rem;
  }

  .hospi-imgs img {
    width: 25rem;
    height: 18.75rem;
    border-radius: 0.75rem;
    background: lightgray 50% / cover no-repeat;
  }

  .image-container {
    position: relative;
  }

  .image-container div img {
    width: 100%;
    height: auto;
    display: block;
  }

  .text-overlay {
    position: absolute;
    bottom: 0;
    width: 100%;
    color: white;

    display: flex;
    padding: 1.25rem 2rem 1.25rem 1.25rem;

    gap: 0.625rem;

    border-radius: 0rem 0rem 0.75rem 0.75rem;
    background: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0.4) 0%,
      rgba(0, 0, 0, 0.2) 100%
    );
    backdrop-filter: blur(6px);

    font-family: Gilroy-SemiBold;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.025rem;
  }

  .hospitality h4 {
    color: #fff;
    font-family: Gilroy-SemiBold;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  /* ////////////////////////////////////// */

  .carousel-container {
    background: #edf0ff;
    border-radius: 1rem;
    width: 55%;
    padding: 1.5rem;
  }

  .carousel-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    text-align: center;
  }

  .carousel-item svg {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  .carousel-item h3 {
    margin-top: 4rem;
    color: #242426;
    text-align: center;
    font-family: Gilroy-Regular;
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.875rem;
    letter-spacing: 0.0275rem;
  }

  .carousel-item h5 {
    color: #222;
    font-family: Gilroy-Medium;
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0275rem;
    margin-top: 4rem;
  }

  .carousel-item h6 {
    margin-top: 1rem;
    color: #8e8e93;
    font-family: Gilroy-Medium;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0225rem;
  }

  .arrows {
    display: flex;
    align-items: flex-start;
    gap: 0.75rem;
    margin-top: -3.5rem;
  }

  .left-arrow,
  .right-arrow {
    display: flex;
    padding: 0.625rem;
    align-items: flex-start;
    gap: 0.625rem;
    border-radius: 6.25rem;
    background: #4f65eb;
    cursor: pointer;
  }

  /* AWARDSSSS */

  .AwardsParent {
    width: 85%;
    margin: 0 auto;
    display: flex;
    gap: 3.25rem;
    padding-bottom: 2.5rem;
  }

  .AwardHeader {
    color: #fffad9;
    font-family: Gilroy-Medium;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.375rem; /* 137.5% */
    letter-spacing: 0.02rem;
  }

  .AwardsPhone {
    display: none;
  }
}

/******************** Mobile view ************ */

@media (min-width: 100px) and (max-width: 1023px) {
  .hospitality {
    display: flex;
    padding: 6.25rem 1.25rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: black;
    gap: 3.75rem;
  }

  .hospitality h2 {
    color: #fff;
    text-align: center;
    font-family: Gilroy-SemiBold;
    font-size: 1.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2.625rem;
    letter-spacing: 0.035rem;
    width: 100%;
  }

  .hospitality span {
    color: #fff;
    font-family: Caveat;
    font-size: 2.625rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2.625rem;
    letter-spacing: 0.0525rem;
  }

  .hospi-imgs {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 1.25rem;
  }

  .hospi-imgs img {
    width: 24.25rem;
    height: 15.625rem;
    border-radius: 0.75rem;
    background: lightgray 50% / cover no-repeat;
  }

  .image-container {
    position: relative;
  }

  .image-container div img {
    width: 100%;
    height: auto;
    display: block;
  }

  .text-overlay {
    position: absolute;
    bottom: 0;
    width: 100%;
    color: white;

    display: flex;
    padding: 1.25rem 2rem 1.25rem 1.25rem;

    gap: 0.625rem;

    border-radius: 0rem 0rem 0.75rem 0.75rem;
    background: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0.4) 0%,
      rgba(0, 0, 0, 0.2) 100%
    );
    backdrop-filter: blur(6px);

    font-family: Gilroy-SemiBold;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.025rem;
  }

  .hospitality h4 {
    color: #fff;
    text-align: center;
    font-family: Gilroy-Medium;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0225rem;
  }

  /* ////////////////////////////////////// */

  .carousel-container {
    background: #edf0ff;
    border-radius: 1rem;
    width: 100%;
    padding: 1rem;
    margin-top: -2.5rem;
  }

  .carousel-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    text-align: center;
  }

  .carousel-item svg {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  .carousel-item h3 {
    margin-top: 3rem;
    color: #242426;
    text-align: center;
    font-family: Gilroy-Regular;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: 0.02rem;
  }

  .carousel-item h5 {
    color: #222;
    font-family: Gilroy-SemiBold;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.02rem;
    margin-top: 3.5rem;
  }

  .carousel-item h6 {
    margin-top: 1rem;
    color: #8e8e93;
    font-family: Gilroy-Medium;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0175rem;
  }

  .arrows {
    display: flex;
    align-items: flex-start;
    gap: 0.75rem;
    margin-top: -3rem;
  }

  .left-arrow,
  .right-arrow {
    display: flex;
    padding: 0.625rem;
    align-items: flex-start;
    gap: 0.625rem;
    border-radius: 6.25rem;
    background: #4f65eb;
    cursor: pointer;
  }

  /* AWARDSSSS */

  .AwardsParent {
    display: none;
  }

  .AwardsPhoneWrapper,
  .AwardsPhone {
    width: 100%;
  }
  .AwardsPhoneWrapper-inner {
    width: 100% !important;
  }
  .AwardSlider {
    width: 50%;
  }

  .AwardHeader {
    color: #fffad9;
    font-family: Gilroy-Medium;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.375rem; /* 137.5% */
    letter-spacing: 0.02rem;
  }
}
