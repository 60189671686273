/* / / / ***************** Full Screen ********************* / / / */


@media (min-width: 1324px) {
  .simplify {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5.5rem 6.25rem;
    background: #EDF0FF;
  }
 
 
  .simplify h2 {
    color: #0f1533;
    text-align: center;
    font-family: Gilroy-SemiBold;
    font-size: 2.625rem;
    font-style: normal;
    font-weight: 400;
    line-height: 3.5rem;
    letter-spacing: 0.0525rem;
  }
 
 
  .simplify h2 span {
    color: #0f1533;
    font-family: Caveat;
    font-size: 3.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: 3.5rem;
    letter-spacing: 0.065rem;
  }
 
 
  .trippleimgs {
    display: flex;
    gap: 1.25rem;
    margin-top: 5rem;
  }
 
 
  .trippleimgs div img {
    width: 25rem;
    height: 29.8125rem;
    border-radius: 0.75rem;
  }
 
 
  .image-container {
    position: relative;
  }
 
 
  .image-container img {
    width: 100%;
    height: auto;
    display: block;
  }
 
 
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: black;
    color: white;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    padding: 20px;
    box-sizing: border-box;
    border-radius: 0.75rem;
  }
 
 
  .overlay h3>span {
    color: #fff;
    font-family: Caveat;
    font-size: 3rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.06rem;
  }
  .overlay h3 {
    color: #FFF;
    font-family: Gilroy-Medium;
    font-size: 2.625rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0525rem;
  }
 
 
  .overlay p {
    color: #fff;
    font-family: Gilroy-Regular;
    font-size: 2rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2.5rem;
    letter-spacing: 0.04rem;
  }
 
 
  .image-container:hover .overlay {
    opacity: 1;
  }
 }
 
 
 @media (min-width: 1024px) and (max-width: 1323px) {
  .simplify {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5.5rem 6.25rem;
    background: #EDF0FF;
  }
 
 
  .simplify h2 {
    color: #0f1533;
    text-align: center;
    font-family: Gilroy-SemiBold;
    font-size: 2.625rem;
    font-style: normal;
    font-weight: 400;
    line-height: 3.5rem;
    letter-spacing: 0.0525rem;
  }
 
 
  .simplify h2 span {
    color: #0f1533;
    font-family: Caveat;
    font-size: 3.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: 3.5rem;
    letter-spacing: 0.065rem;
  }
 
 
  .trippleimgs {
    display: flex;
    gap: 1.25rem;
    margin-top: 5rem;
  }
 
 
  .trippleimgs div img {
    width: 25rem;
    height: 29.8125rem;
    border-radius: 0.75rem;
  }
 
 
  .image-container {
    position: relative;
  }
 
 
  .image-container img {
    width: 100%;
    height: auto;
    display: block;
  }
 
 
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: black;
    color: white;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    padding: 20px;
    box-sizing: border-box;
    border-radius: 0.75rem;
  }
 
 
  .overlay h3 {
    color: #fff;
    font-family: Caveat;
    font-size: 3rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.06rem;
  }
 
 
  .overlay p {
    color: #fff;
    font-family: Gilroy-Regular;
    font-size: 1.7rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2.5rem;
    letter-spacing: 0.04rem;
  }
 
 
  .image-container:hover .overlay {
    opacity: 1;
  }
 }
 
 
 /* / / / *****************Mobile View ******************* / / / */
 
 
 @media (min-width: 100px) and (max-width: 1023px) {
  .simplify {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2.5rem;
    padding: 6.25rem 1.25rem;
    background: #EDF0FF;
  }
 
 
  .simplify h2 {
    color: #0f1533;
    font-family: Gilroy-SemiBold;
    font-size: 1.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2.625rem;
    letter-spacing: 0.035rem;
    width: 100%;
 
 
  }
 
 
  .simplify h2 span {
    color: #0F1533;
    font-family: Caveat;
    font-size: 2.625rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2.625rem;
    letter-spacing: 0.0525rem;
  }
 
 
  .trippleimgs {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
  }
 
 
  .trippleimgs div img {
    width: 25rem;
    height: 29.8125rem;
    border-radius: 0.75rem;
  }
 
 
  .image-container {
    position: relative;
  }
 
 
  .image-container img {
    width: 100%;
    height: auto;
    display: block;
  }
 
 
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: black;
    color: white;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    padding: 20px;
    box-sizing: border-box;
    border-radius: 0.75rem;
  }
 
 
  .overlay h3 {
    color: #fff;
    font-family: Caveat;
    font-size: 3rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.06rem;
  }
 
 
  .overlay p {
    color: #fff;
    font-family: Gilroy-Regular;
    font-size: 2rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2.5rem;
    letter-spacing: 0.04rem;
  }
 
 
  .image-container:hover .overlay {
    opacity: 1;
  }
 }
 
 
 
 
 
 