/***************************Laptop View**********************/

.navbar {
  left: 0;
  height: 10vh;
  display: flex;
  font-size: 1.5rem;
  font-family: sans-serif;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 9999;
  padding: 0 4rem 0 8rem;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.navbar a {
  color: black;
}

.navbar::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: -1;
}


.logo {
  display: flex;
  align-items: center;
}

.menu {
  width: 65%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.menu ul {
  display: flex;
  align-items: center;
  gap: 3rem;
  color: black;
}

.button {
  border: none;
  background: #5172F6;
  border-radius: 100px;
  height: 4.5rem;
  width: 15rem;
}

.button:hover {
  background: rgb(44, 44, 225);
  cursor: pointer;
}

.button-text {
  text-align: center;
  color: white;
}

.hamburger {
  display: none;
}

.menu .logos {
  display: none;
}

/* / ***************** Mobile View ************************** /  */

@media (max-width: 1023px) {


.button {
      display: none;
  }

  .menu {
      display: none;
  }

  .hamburger {
      display: block;
      font-size: 3.5rem;
      padding-top: 10px;
      color: white;
      background: none;
      border: none;
      cursor: pointer;
    }
    
  body.menu-open {
      overflow-y: hidden;
    }

  .navbar {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 40px 40px;
  }


  .mobile-menu ul {
      position: fixed;
      top: 10vh;
      left: 0;
      height: 90vh; 
      width: 100%;
      margin: 0;
      padding: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 2rem;
      font-size: 1.5rem;
      font-weight: 400;
      background-color: rgb(255, 255, 255);
      z-index: 9998;
      overflow-y: auto;
    }
  
    .mobile-menu {
      display: none;
      width: 98%;
      background-color: transparent;
      display: grid;
      grid-column: 3/5;
      align-items: center;
      transition: all 3s linear;
      transform-origin: top;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px;
      overflow-y: auto;
      z-index: 9997;
    }

  .mobile-menu ul li:first-child {
      transition-delay: 3s;
  }


  .hamburger:focus+.mobile-menu,
  .hamburger:checked+.mobile-menu {
      display: grid;
  }


  .mobile-menu ul li {
      transition: opacity 1s ease;
      color: black;
  }


  .mobile-menu ul li a {
      color: black;
  }

  .mobile-menu ul li:first-child {
      transition-delay: 0.2s;
  }

  .mobile-menu ul li:hover {
      opacity: 0.7;
  }
 
  .mobile-menu .logos {
      display: block;
      height: 5rem;
      display: flex;
      width:30%;
      justify-content: space-between;
      
  }
   
  .mobile-menu .insta{
      font-size: 2.3rem;
  }
    
  
}