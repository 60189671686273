/************************** Full screen ********************** */
@media (min-width: 1024px) {
  .features {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem 0rem 4rem 0rem;
  }
 
 
  .text-feature {
    width: 50%;
  }
 
 
  .features h2 {
    color: #0f1533;
    text-align: center;
    font-family: Gilroy-SemiBold;
    font-size: 2.625rem;
    font-style: normal;
    font-weight: 400;
    line-height: 3.5rem;
    letter-spacing: 0.0525rem;
  }
 
 
  .features span {
    color: #0f1533;
    font-family: Caveat;
    font-size: 3.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: 3.5rem;
    letter-spacing: 0.065rem;
  }
 
 
  .container-box,
  .container-box2 {
    display: flex;
    justify-content: space-between;
    height: 28rem;
    width: 85%;
    border-radius: 2.25rem;
    margin-top: 5rem;
  }
 
 
  .container-box {
    background: #edf0ff;
    padding: 0rem 3.13rem 0 3.13rem;
  }
 
 
  .container-box2 {
    background: #ebebed;
    padding: 0rem 3.13rem 0 3.13rem;
  }
 
 
  .left-box {
    width: 45%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2rem;
  }
 
 
  .container-box div h3,
  .container-box2 div h3 {
    color: #000;
    font-family: Gilroy-Medium;
    font-size: 1.625rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0325rem;
  }
 
 
  .container-box div h5,
  .container-box2 div h5 {
    color: #242426;
    font-family: Gilroy-Regular;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.625rem;
    letter-spacing: 0.025rem;
  }
 
 
  .right-box {
    width: 50%;
    padding-top: 2.5rem;
    display: flex;
    min-height: 20rem;
    /* border: 2px solid red; */
  }
 
 
  .right-box img {
    width: 100%;
    height: auto;
  }
 
 
  .fifthFeature>.right-box{
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
 }
 
 
 /******************** Mobile view ************ */
 
 
 @media (min-width: 100px) and (max-width: 1023px) {
  .features {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem 1.25rem;
  }
 
 
  .text-feature {
    width: 95%;
  }
 
 
  .features h2 {
    color: #0f1533;
    text-align: center;
    font-family: Gilroy-SemiBold;
    font-size: 1.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2.625rem;
    letter-spacing: 0.035rem;
  }
 
 
  .features span {
    color: #0f1533;
    font-family: Caveat;
    font-size: 2.625rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2.625rem;
    letter-spacing: 0.0525rem;
  }
 
 
  .container-box,
  .container-box2 {
    justify-content: space-between;
    width: 100%;
    border-radius: 1.5rem;
    margin-top: 2.5rem;
    gap: 2.19rem;
  }
 
 
  .container-box{
    display: flex;
    flex-direction: column;
    background: #edf0ff;
    padding: 1.25rem 1.25rem 0rem 1.25rem;
  }
 
 
  .container-box2{
    display: flex;
    flex-direction: column-reverse;
    background: #ebebed;
    padding: 1.25rem;
  }
 
 
  .left-box {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
  }
 
 
  .container-box div h3,
  .container-box2 div h3 {
    color: #000;
    font-family: Gilroy-Medium;
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0275rem;
  }
 
 
  .container-box div h5,
  .container-box2 div h5 {
    color: #242426;
    font-family: Gilroy-Regular;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: 0.02rem;
  }
 
 
  .right-box {
    width: 100%;
    display: flex;
    align-items: flex-end;
  }
 
 
  .right-box img {
    width: 100%;
    height: auto;
  }
 }
 
 
 
 
 
 