:root {
  --primary-clr: #6c00f9;
  --white: #fff;
  --text-clr: #464646;
  --tabs-list-bg-clr: linear-gradient(180deg, #203E89 0%, #203E89 0.01%, #6622BC 100%);
  --btn-hvr: black;
}

/* ****************** Laptop View *************************** */

@media (min-width: 1024px) {

  .terms {
    font-size: 2rem;
    color: var(--text-clr);
  }


  .tc_wrap {
    height: 90vh;
    align-items: center;
    justify-content: center;
    background: var(--white);
    display: flex;
    border-bottom: 1px solid black;
  }

  .tabs_list {
    width: 26%;
    height: 100%;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow-y: auto;
    background: var(--tabs-list-bg-clr);

  }

  .tabs_content {
    width: 74%;
    padding: 6rem 8rem;
    height: 100%;
    overflow-y: auto;
  }

  .tab_head {
    color: #5172F6;
    text-align: center;
  }

  .tabs_list ul {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 4rem;
    padding-right: 2rem;
    text-align: right;
  }

  .tabs_list ul li {
    position: relative;
    cursor: pointer;
    font-weight: bold;
    transition: top 0.2s ease-in-out;
  }

  .tabs_list ul li.active{
    background-color: white;
    color: black;
    width: 90%;
    height: 6rem;
    padding-top: 1.2rem;
    padding-right: 3rem;
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
    margin-left: 7rem;
   
  }

  .tabs_list ul li.active:before {
    opacity: 1;
  }

  .tab_body {
    margin-top: 2rem;
  }

  .tab_item p {
    font-size: 2.5rem;
    margin-top: 4rem;
    text-align: justify;

  }

  .terms{
    display: block;
  }

  .terms2{
    display: none;
  }
}


/* * ******************* Tablet Screen ************************ */

@media (min-width: 641px) and (max-width: 1023px) {

  .terms {
    font-size: 2rem;
    color: var(--text-clr);
  }

  .tc_wrap {
    height: 90vh;
    align-items: center;
    justify-content: center;
    background: var(--white);
    display: flex;
    border-bottom: 1px solid black;
  }

  .tabs_list {
    width: 30%;
    color: white;
    height: 100%;
    padding: 2rem 2rem 2rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: var(--tabs-list-bg-clr);
    /* border: 10px solid black; */
  }

  .tabs_content {
    width: 70%;
    padding: 6rem 5rem;
    height: 100%;
    overflow-y: auto;
  }

  .tab_head {
    color: #5172F6;
    text-align: center;
  }

  .tabs_list ul {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 5rem;
    text-align: right;
    padding-left: 3rem;
  }

  .tabs_list ul li {
    position: relative;
    cursor: pointer;
    font-weight: bold;
    transition: top 0.2s ease-in-out;
  }

  .tabs_list ul li.active {
    background-color: white;
    color: black;
    width: 100%;
    height: 7rem;
    padding-top: 1.8rem;
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
    margin-left: 2rem;
  }

  .tabs_list ul li:nth-child(3).active,
  .tabs_list ul li:nth-child(4).active,
  .tabs_list ul li:nth-child(6).active{
    padding: 1rem 0rem;
  }

  .tabs_list ul li.active:before {
    opacity: 1;
  }

  .tab_body {
    margin-top: 2rem;
  }

  .tab_item p {
    font-size: 2.5rem;
    margin-top: 4rem;
    text-align: justify;
  }

  .terms{
    display: block;
  }

  .terms2{
    display: none;
  }
  
}

/* ***********************Mobile Version ************************ */

@media (min-width: 100px) and (max-width: 640px) {
  
  .terms {
    font-size: 1.6rem;
  }

  .tc_wrap {
    height: 100vh;
    align-items: center;
    justify-content: center;
    background: var(--white);
    display: flex;
    border-bottom: 1px solid black;
  }

  .tabs_list {
    width: 100%;
    color: white;
    height: 100%;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: var(--tabs-list-bg-clr);
    overflow-y: auto;
  }

  .tabs_content {
    width: 100%;
    padding: 2rem;
    height: 100%;
    overflow-y: auto;
  }

  .tab_head {
    color: #5172F6;
    text-align: center;
  }

  .tabs_list ul {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2rem;
  }

  .tabs_list ul li {
    position: relative;
    cursor: pointer;
    font-weight: bold;
    transition: all 0.5s ease;
    text-align: center;
  }

  .tabs_list ul li:before {
    content: "";
    position: absolute;
    bottom: -1rem;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: 0.2rem;
    background: white;
    opacity: 0;
    transition: top 0.2s ease-in-out;
  }

  .tabs_list ul li.active,
  .tabs_list ul li:hover {
    color: rgb(182, 175, 175);
  }

  .tabs_list ul li.active:before {
    opacity: 1;
  }

  .tab_body {
    margin-top: 2rem;
  }

  .tab_item p {
    font-size: 1.8rem;
    margin-top: 2rem;
    text-align: justify;
  }

  .terms{
    display: none;
  }

  .terms2{
    display: block;
  }

  /* ************ dropdown css ***************** */
  .terms2{
    display: block;
    padding: 5rem 0;
    display: flex;
    width: 100%;
    flex-direction: column;
   justify-content: center;
   align-items: center;
   gap: 4rem;
   background: linear-gradient(180deg, #203E89 0%, #203E89 0.01%, #6622BC 100%);;
   font-family: sans-serif;
  }
  
  .terms2 h1{
    font-size: 3rem;
    font-weight: 800;
    color: white;
  }

  .terms2 p{
   font-size: 1.8rem;
   width: 90%;
   color: white;
   padding: 2rem 1rem 2rem 2rem;
  text-align: justify;
  }

  .dropdown{
    width: 100%;
   padding-left: 4rem;
   overflow-y: auto;
   
  }

  .dropdown-btn{
    width: 90%;
    padding: 2rem 0rem;
    border: none;
    font-size: 1.8rem;
    font-weight: 500;
    margin-bottom: 1rem;
    border-radius: 1rem;
  }
  
  .dropdown-btn:hover{
    color: white;
    background-color: black;
  }

  
}