/* / / / ***************** Full Screen ********************* / / / */

@media (min-width: 1024px) {
  .contactBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 3.3rem;
  }

  .contactBox h2 {
    color: #242426;
    text-align: center;
    font-family: Gilroy-SemiBold;
    font-size: 2.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.05rem;
  }

  .contactBox h4 {
    color: #242426;
    text-align: center;
    font-family: Gilroy-Medium;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: 0.02rem;
    width: 65%;
    margin-top: 1.5rem;
  }

  .insideContactBox {
    display: flex;
    gap: 1.25rem;
    width: 80%;
    margin-top: 5rem;
  }

  .insideContactBox div {
    border-radius: 1.5rem;
    border: 1px solid #d8d8dc;
    background: #fff;
    width: 48%;

    display: flex;
    padding: 2rem 3rem 2rem 1.5rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.8rem;
  }

  .insideContactBox h3 {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1.625rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0325rem;
  }

  .insideContactBox h6 {
    color: #4a6afe;
    font-family: Gilroy-SemiBold;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.625rem;
    letter-spacing: 0.025rem;
  }

  .insideContactBox h5 {
    color: #242426;
    font-family: Gilroy-Regular;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.625rem;
    letter-spacing: 0.025rem;
  }
}

/* / / / *****************Mobile View ******************* / / / */

@media (min-width: 100px) and (max-width: 1023px) {
    .contactBox {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 2.5rem;
      }
    
      .contactBox h2 {
        color: #242426;
        text-align: center;
        font-family: Gilroy-SemiBold;
        font-size: 1.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.0375rem;
      }
    
      .contactBox h4 {
        color: #242426;
        text-align: center;
        font-family: Gilroy-Medium;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.5rem; 
        letter-spacing: 0.02rem;
        width: 90%;
        margin-top: 1.5rem;
      }
    
      .insideContactBox {
        display: flex;
        flex-direction: column;
        gap: 1.25rem;
        width: 90%;
        margin-top: 5rem;
      }
    
      .insideContactBox div {
        border-radius: 1.5rem;
        border: 1px solid #d8d8dc;
        background: #fff;
        width: 100%;
    
        display: flex;
        padding: 2rem 1rem 2rem 1.5rem;
        flex-direction: column;
        align-items: flex-start;
        gap: 1.25rem;
      }
    
      .insideContactBox h3 {
        color: #242426;
        font-family: Gilroy-SemiBold;
        font-size: 1.625rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.0325rem;
      }
    
      .insideContactBox h6 {
        color: #4a6afe;
        font-family: Gilroy-SemiBold;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.625rem;
        letter-spacing: 0.025rem;
      }
    
      .insideContactBox h5 {
        color: #242426;
        font-family: Gilroy-Regular;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.625rem;
        letter-spacing: 0.025rem;
      }
}
