/* Full Screen */
@media (min-width: 1024px) {
  .wholePage {
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: 130vh;
  }

  .mobileTopLogo {
    display: none;
  }

  .leftBox {
    width: 49%;
    padding: 3.75rem;
    background: linear-gradient(180deg, #4a6afe 0%, #25357f 100%);
  }

  .leftBox h3 {
    padding-top: 3.75rem;
    color: #fff;
    font-family: Gilroy-Medium;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 4rem;
  }

  .insideLeftBox {
    display: flex;
    flex-direction: column;
    gap: 1.38rem;
  }

  .insideLeftBox h2 {
    color: #fff;
    font-family: Gilroy-SemiBold;
    font-size: 1.625rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0325rem;
    margin-top: 2rem;
  }

  .insideLeftBox h4 {
    color: #fff;
    font-family: Gilroy-Regular;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.375rem; /* 137.5% */
    letter-spacing: 0.02rem;
  }

  .rightBox {
    width: 49%;
    padding: 3.75rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .rightBox h2 {
    color: #000;
    text-align: center;
    font-family: Gilroy-SemiBold;
    font-size: 2.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.05rem;
  }

  .rightBox h3 {
    color: #000;
    text-align: center;
    font-family: Gilroy-Medium;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: 0.02rem;
  }

  .rightBox img {
    width: 20rem;
    margin-top: 1.8rem;
  }

  .RightBoxForm {
    margin-top: 2.5rem;
    width: 100%;
    height: 40rem;
  }

  .insideFormInput {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    margin-top: 1.25rem;
  }

  .insideFormInput2 {
    display: flex;
    gap: 1.25rem;
    margin-top: 1.25rem;
  }

  .insideFormInput2 div {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
  }

  .insideFormInput h5,
  .insideFormInput2 h5 {
    color: #242426;
    font-family: Gilroy-Medium;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0175rem;
  }

  .insideFormInput input,
  .insideFormInput2 input {
    display: flex;
    padding: 0.9375rem 0.9rem 0.9375rem 1rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    border-radius: 0.5rem;
    border: 1px solid #d8d8dc;

    color: black;
    font-family: Gilroy-Medium;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0175rem;
  }

  .nextBttn {
    width: 100%;
    margin-top: 1.87rem;
  }

  .getStartbttn {
    width: 75%;
  }

  .nextBttn,
  .getStartbttn {
    border-radius: 6.25rem;
    background: #4a6afe;
    display: inline-flex;
    padding: 0.875rem 0.9rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    cursor: pointer;
    color: #fff;
    font-family: Gilroy-SemiBold;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.02rem;
  }

  .belowButtonText {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1.25rem;
  }

  .belowButtonText h4 {
    color: #242426;
    font-family: Gilroy-Medium;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0175rem;
  }

  .belowButtonText span {
    color: #4a6afe;
    font-family: Gilroy-SemiBold;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0175rem;
    cursor: pointer;
  }

  .formNavigation {
    display: flex;
    gap: 0.75rem;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-top: 1.87rem;
  }

  .backBttn {
    border-radius: 6.25rem;
    border: 1px solid #4a6afe;
    display: inline-flex;
    padding: 0.875rem 0.9rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    width: 23%;

    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.02rem;
  }

  .thirdStepBox {
    display: flex;
    flex-direction: column;
    gap: 3.87rem;
  }

  .thirdStepBox h3 {
    color: #242426;
    font-family: Gilroy-Medium;
    font-size: 1.25rem;
    font-style: normal;
    text-align: justify;
    font-weight: 400;
    line-height: 1.625rem;
  }

  .thirdStepBox h3:nth-child(1) {
    margin-top: 2rem;
  }
}

/* Mobile View */
@media (min-width: 100px) and (max-width: 1023px) {
  .leftBox {
    display: none;
  }

  .wholePage {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .rightBox {
    width: 100%;
    padding: 1.25rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .rightBox h2 {
    color: #242426;
    text-align: center;
    font-family: Gilroy-SemiBold;
    font-size: 1.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0375rem;
  }

  .rightBox h3 {
    color: #242426;
    text-align: center;
    font-family: Gilroy-Medium;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: 0.02rem;
  }

  .rightBox img {
    width: 18rem;
    margin-top: 1.8rem;
  }

  .RightBoxForm {
    margin-top: 2.5rem;
    width: 100%;
    height: 40rem;
  }

  .insideFormInput {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    margin-top: 1.25rem;
  }

  .insideFormInput2 {
    display: flex;
    justify-content: space-between;
    margin-top: 1.25rem;
  }

  .insideFormInput2 div {
    width: 47.5%;
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
  }

  .insideFormInput h5,
  .insideFormInput2 h5 {
    color: #242426;
    font-family: Gilroy-Medium;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0175rem;
  }

  .insideFormInput input,
  .insideFormInput2 input {
    display: flex;
    padding: 0.9375rem 0.9rem 0.9375rem 1rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    border-radius: 0.5rem;
    border: 1px solid #d8d8dc;

    color: black;
    font-family: Gilroy-Medium;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0175rem;
  }

  .nextBttn {
    width: 100%;
    margin-top: 1.87rem;
  }

  .getStartbttn {
    width: 75%;
  }

  .nextBttn,
  .getStartbttn {
    border-radius: 6.25rem;
    background: #4a6afe;
    display: inline-flex;
    padding: 0.875rem 0.9rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    cursor: pointer;
    color: #fff;
    font-family: Gilroy-SemiBold;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.02rem;
  }

  .belowButtonText {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1.25rem;
  }

  .belowButtonText h4 {
    color: #242426;
    font-family: Gilroy-Medium;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0175rem;
    padding-bottom: 2rem;
  }

  .belowButtonText span {
    color: #4a6afe;
    font-family: Gilroy-SemiBold;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0175rem;
    cursor: pointer;
  }

  .formNavigation {
    display: flex;
    gap: 0.75rem;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-top: 1.87rem;
  }

  .backBttn {
    border-radius: 6.25rem;
    border: 1px solid #4a6afe;
    display: inline-flex;
    padding: 0.875rem 0.9rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    width: 23%;

    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.02rem;
  }

  .thirdStepBox {
    display: flex;
    flex-direction: column;
    gap: 3.87rem;
  }

  .thirdStepBox h3 {
    color: #242426;
    font-family: Gilroy-Medium;
    font-size: 1.125rem;
    font-style: normal;
    text-align: justify;
    font-weight: 400;
    line-height: 1.625rem;
  }

  .thirdStepBox h3:nth-child(1) {
    margin-top: 2rem;
  }

  .mobileTopLogo {
    width: 100%;
    display: flex;
    padding: 1.375rem 1.25rem;
  }
}
