/* Full Screen */
@media (min-width: 1024px) {
  .newnav {
    width: 100%;
    height: 5.3rem;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: black;
    padding: 1.25rem 6.25rem;
    position: sticky;
    top: 0;
    z-index: 9999;
    transition: background-color 0.3s ease, color 0.3s ease;
  }

  .newnavtwo {
      width: 100%;
      height: 5.3rem;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: white;
      padding: 1.25rem 6.25rem;
      position: sticky;
      top: 0;
      z-index: 9999;
      transition: background-color 0.3s ease, color 0.3s ease;
  }
 
 
  .top-sticky {
    position: sticky;
    top: 0;
    z-index: 1000;
  }
 
 
  .newnav.sticky {
    background-color: #fff;
  }
 
 
  .newnav.sticky .newmenu a,
  .newnav.sticky .buttons a {
    color: #000;
  }
 
 
  .newlogo {
    width: 6.9375rem;
    height: 1.875rem;
    flex-shrink: 0;
  }
 
 
  .newmenu {
    display: flex;
    justify-content: flex-end;
    gap: 2rem;
    align-items: center;
    color: #fff;
    text-align: center;
    font-family: Gilroy-Medium;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.02rem;
    width: 48%;
  }
 
 
  .newmenu ul {
    display: flex;
    align-items: center;
    gap: 2rem;
  }
 
 
  .buttons {
    display: flex;
    gap: 0.75rem;
    justify-content: flex-end;
  }
 
 
  .newbutton,
  .button2,
  .buttonTwo {
    display: flex;
    padding: 0.7rem 1.2rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    border-radius: 6.25rem;
    cursor: pointer;
  }
 
 
  .newbutton {
    background: #4a6afe;
    border: none;
  }
 
 
  .newbutton:hover {
    background: rgb(44, 44, 225);
  }
 
 
  .button2 {
    border: 1px solid #fff;
    color: white;
    background-color: #000;
  }

  .buttonTwo{
    border: 1px solid black;
    color: black;
    background-color: #fff;
  }
 
  .buttonTwo:hover{
    background-color: black;
    color: white;
  }
 
  .button2:hover {
    background: white;
    color: #000;
  }
 
 
  .newnav.sticky .button2 {
    border: 1px solid black;
    background-color: #fff;
    color: #000;
  }
 
 
  .newnav.sticky .button2:hover {
    border: 1px solid white;
    background-color: black;
    color: white;
  }
 
 
  .hamburger,
  .sidepanel {
    display: none;
  }
 }
 
 
 /* Mobile View */
 @media (min-width: 100px) and (max-width: 1023px) {
  .newnav {
    width: 100%;
    height: 5.3rem;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: black;
    padding: 1.44rem 1.25rem;
    position: sticky;
    top: 0;
    z-index: 9999;
    transition: background-color 0.3s ease, color 0.3s ease;
  }

  .newnavtwo {
    width: 100%;
    height: 5.3rem;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    padding: 1.44rem 1.25rem;
    position: sticky;
    top: 0;
    z-index: 9999;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
 
 
  .newnav.sticky {
    background-color: #fff;
  }
 
 
  .newnav.sticky .newmenu a,
  .newnav.sticky .buttons a,
  .newnav.sticky .button-text2 {
    color: #000;
  }
 
 
  .newlogo {
    width: 6.9375rem;
    height: 1.875rem;
    flex-shrink: 0;
  }
 
 
  .newmenu {
    display: none;
  }
 
 
  .hamburger {
    display: block;
    font-size: 2rem;
    color: #fff;
    cursor: pointer;
  }

  .newnavtwo .hamburger{
    display: block;
    font-size: 2rem;
    color: #000;
    cursor: pointer;
  }
 
 
  .newnav.sticky .hamburger {
    color: #000;
  }

  .newnavtwo.sticky .hamburger {
    color: #000;
  }
 
 
  /* .buttons {
    display: none;
  } */
 
 
  .buttons {
    width: 100%;
    display: flex;
    justify-content: end;
    align-items: center;
    text-align: center;
    padding-right: 1.25rem;
  }
 
 
  .button2,
  .buttonTwo {
    display: none;
  }
 
 
  .button2Sidepanel {
    width: 100%;
    justify-content: center;
    display: flex;
    padding: 0.5rem 0.875rem;
    gap: 0.625rem;
    border-radius: 6.25rem;
    outline: 1px solid black;
    background-color: #fff;
    color: #000;
  }
 
 
  .newbutton {
    display: flex;
    padding: 0.5rem 0.875rem;
    gap: 0.625rem;
    border-radius: 6.25rem;
  }

  .newbutton {
    background: #4a6afe;
    border: none;
  }
 
 
  .newbuttonSidepanel {
    display: flex;
    justify-content: center;
    border-radius: 6.25rem;
    width: 100%;
    padding: 0.7rem 0.875rem;
    background: #4a6afe;
    border: none;
  }
 
 
  .button-textSidepanel {
    color: #fff;
    font-family: Gilroy-SemiBold;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.02rem;
  }
 
 
  .newbuttonSidepanel2 {
    display: flex;
    justify-content: center;
    border-radius: 6.25rem;
    padding: 0.7rem 0.85rem;
    background: #4a6afe;
    border: none;
  }
 
 
  .button-textSidepanel2 {
    color: #fff;
    font-family: Gilroy-SemiBold;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.02rem;
  }
 
 
  /* .newbutton:hover {
    background: rgb(44, 44, 225);
  } */
 
 
  .hamburger {
    display: flex;
    justify-content: flex-start;
    align-items: start;
    padding-bottom: 0.5rem;
  }
 
 
  /* ////// sidepanel */

  .sidepanel {
    height: 100%; 
    width: 100%; 
    position: fixed;
    z-index: 10000;
    bottom: 0;
    background-color: #fff;
    overflow-y: hidden;
    transition: transform 0.5s, opacity 0.5s;
    transform: translateY(100%);
    opacity: 0;
    padding-top: 65px;
  }

  .sidepanel.open {
    transform: translateY(0); 
    opacity: 1;
  }

  .sidepanel > a {
    padding: 8px 32px;
    text-decoration: none;
    font-size: 25px;
    color: #111;
    display: block;
    transition: 0.3s;
  }

  .sidepanel .closebtn {
    position: absolute;
    top: 0;
    right: 2px;
    font-size: 36px;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    padding: 0.5rem 2rem;
  }

  .SidepanelLogoDIv {
    display: flex;
    justify-content: space-between;
    width: 94%;
    padding-right: 1rem;
    align-items: center;
  }

  .buttonsSidepanel {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    padding: 2rem;
  }
 }
 
 
 
 
 
 