
/* ****************** Laptop View *************************** */

@media (min-width: 1024px) {
    .footer {
        display: flex;
        flex-direction: row-reverse;
        width: 100%;
        height: 43rem;
        justify-content: space-evenly;
        background: #FFF;
    }

.footer-container{
    display: flex;
    width: 50%;
    justify-content: space-between;
    flex-direction: row-reverse;
}


    .signup {
        width: 37%;
        color: #10151C;
        text-align: center;
        padding-top: 10rem;
        font-weight: 500;
    }

    .signup>h3 {
        font-size: 1.8rem;
        margin-bottom: 2rem;
    }

    input[name="users_email"] {
        border-radius: 10px;
        background-color: #10151C;
        margin-right: 2rem;
        width: 22rem;
        height:5rem;
        padding: 2rem;
        font-size: 1.8rem;
        border: none;
        color: white;
    }

    input[name="footer-signup"]{
        border: none;
        background: #5172F6;
        border-radius: 10px;
        padding: 1.5rem 3rem;
        text-align: center;
        color: white;
        font-size: 1.8rem;
    }

    input[name="footer-signup"]:hover {
        background: #2663FF;
        cursor: pointer;
    }

    .sitemap {
        margin: auto 0;   
    }

    .sitemap>h3 {
        font-size: 2rem;
        font-weight: bold;
        margin-bottom: 2rem;

    }

    .list {
        font-size: 1.9rem;
        line-height:3.5rem;
    }

    .address {
        width: 40%;
        display: flex;
        font-size: 2rem;
        flex-direction: column;
        line-height: 3rem;
        align-items: start;
        justify-content: center;
        gap: 2rem;
    }

    .address>h5 {
        font-size: 1.8rem;
        font-weight: 500;
    }

    .address>img {
        width: 15rem;
    }

    .logos {
        width: 15rem;
        display: flex;
        flex-direction: row;
        gap: 2rem;
    }

    .insta {
        height: 2.5rem;
        width: 2.5rem;
        font-size: 2.5rem;
        color: black;
    
    }
}




/* ******************* Mobile View ***************************** */

@media (min-width: 100px) and (max-width: 640px) {

    .footer {
        display: flex;
        flex-direction: column;
        width: 100%;
        text-align: left;
        justify-content: space-between;
        padding: 8rem 0;
        background: #FFF;
        padding-left: 4rem;
        backdrop-filter: blur(2px);
    }
   
    .footer-container{
        margin-top: 3rem;
        display: flex;
        flex-direction: row-reverse;
        gap: 4rem;
        /* border: 1px solid red; */
    }

    .signup {
        width: 92%;
        color: #10151C;
        margin-bottom: 1.5rem;
    }
    
    .signup>h3 {
        font-size: 2rem;
        font-weight: 500;
         margin-bottom: 1.5rem;
        }

        input[name="users_email"] {
        border-radius: 10px;
        background: #10151C;
        width: 20rem;
        height: 4.9rem;
        padding: 2rem;
        font-size: 2rem;
        border: none;
        margin-top: 1rem;
        margin-right: 1rem;
        color: white;
    }

    input[name="footer-signup"]{
        border: none;
        margin-top: 1rem;
        background: #2663FF;
        color: white;
        border-radius: 10px;
        padding: 1.4rem 2rem;
        font-size: 1.8rem;
    }

    .text {
        text-align: center;
        color: white;
    }

    .sitemap {
        width: 80%;
        margin-bottom: 0;
        text-align: left;
    }

    .sitemap>h3 {
        font-size: 2rem;
        font-weight: 600;

    }

    .list {
        font-size: 1.8rem;
        padding-top: 1rem;
        font-weight: 400;
        line-height: 3rem;
    }


    .address {
        width: 52%;
        height: 20rem;
        display: flex;
        gap: 0.8rem;
        flex-direction: column;
        line-height: 2rem;
        align-items: start;
    }

    .address>h5 {
        font-size: 1.5rem;
        align-items: start;
        font-weight: 500;
    }

    .logos {
        height: 2rem;
        width: 20rem;
        display: flex;
        flex-direction: row;
        gap: 2rem;
    }

    .insta {
        height: 2.5rem;
        width: 2.5rem;
        font-size: 2.5rem;
        color: black;
    }
}


/* ******************* tablet Screen ************************ */


@media(min-width : 641px) and (max-width: 1023px) {
    .footer {
        display: flex;
        flex-direction: column;
        width: 100%;
        text-align: left;
        justify-content: space-between;
        padding: 5rem 0;
        background: #FFF;
        padding-left: 4rem;
        backdrop-filter: blur(2px);
    }


    .signup {
        width: 92%;
        color: #10151C;
        margin-bottom: 1.5rem;
    }
    
    .signup>h3 {
        font-size: 2rem;
        font-weight: 500;
         margin-bottom: 1.5rem;
        }

        input[name="users_email"]{
        border-radius: 10px;
        background: #10151C;
        width: 20rem;
        height: 4.9rem;
        padding: 2rem;
        font-size: 2rem;
        border: none;
        margin-top: 1rem;
        margin-right: 1rem;
        color: white;
    }


    input[name="footer-signup"]{
        border: none;
        margin-top: 1rem;
        background-color: #2663FF;
        color: white;
        border-radius: 10px;
        padding: 1.4rem 2rem;
        font-size: 1.8rem;
    }

    input[name="footer-signup"]:hover {
        background: #2663FF;
        cursor: pointer;
    }

    .text {
        text-align: center;
        color: white;
    }

    .footer-container{
        display: flex;
        flex-direction: row-reverse;
        margin-top: 3rem;  
        gap: 5rem;
    }

    .sitemap {
        width: 50%;
        margin-bottom: 0;
        text-align: left;
        /* border: 1px solid red; */
    }

    .sitemap>h3 {
        font-size: 2rem;
        font-weight: 600;

    }

    .list {
        font-size: 1.8rem;
        padding-top: 1rem;
        font-weight: 400;
        line-height: 3rem;
    }


    .address {
        width: 52%;
        height: 25rem;
        display: flex;
        gap: 2rem;
        flex-direction: column;
        line-height: 2rem;
        align-items: start;
    }

    .address>h5 {
        font-size: 1.5rem;
        align-items: start;
        font-weight: 500;
    }

    .logos {
        height: 2rem;
        width: 20rem;
        display: flex;
        flex-direction: row;
        gap: 2rem;
    }

    .insta {
        height: 2.5rem;
        width: 2.5rem;
        font-size: 2.5rem;
        color: black;
    }


}
