/* Full Screen */
@media (min-width: 1024px) {
  .outerKyc {
    position: relative;
  }

  .topKyc {
    width: 100%;
    display: flex;
    padding: 1.75rem 2rem 1.75rem 6.5rem;
  }

  .hoverBox {
    display: flex;
    flex-direction: column;
    border-radius: 1.5rem;
    background: #fff;
    padding: 1.5rem 1.25rem;
    width: 25%;

    position: absolute;
    top: 50%;
    right: 15%;
    transform: translate(0, -50%);
    z-index: 10;
  }

  .hoverBox h3 {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.625rem;
    letter-spacing: 0.02rem;
  }

  .hoverBox h4 {
    color: #8e8e93;
    font-family: Gilroy-Medium;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0175rem;
  }

  .lineKycBox {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 0.5rem;
  }

  .lineKycBox svg {
    margin-top: 1.25rem;
  }

  .lineKycBox h2 {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.125rem;
    margin-top: 1.25rem;
  }

  .hoverBox button {
    border-radius: 6.25rem;
    background: #4a6afe;
    display: flex;
    padding: 0.7rem 1.3rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    width: 97%;
    margin-top: 2rem;
    color: #fff;
    font-family: Gilroy-SemiBold;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.02rem;
  }

  /* screen 1 */
  .secondScreenKyc {
    width: 100%;
    display: flex;
    flex-direction: column;
    background: #f4f6fb;
  }

  .innerSecondScreenKyc {
    width: 100%;
    display: flex;
    align-items: start;
    justify-content: center;
    gap: 1.25rem;
    padding: 5rem 8rem;
  }

  .leftBoxKyc {
    width: 35%;
    /* height: 20.875rem; */
    /* border-radius: 1.5rem;  */
    /* border: 1px solid #d8d8dc; */
    /* background: #fff; */
    /* padding: 1.5rem 1.25rem; */
  }

  .leftBoxKyc h2 {
    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.025rem;
  }

  .outerRightBox {
    width: 55%;
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
  }

  .rightBoxKyc {
    width: 100%;
    border-radius: 1.5rem;
    border: 1px solid #d8d8dc;
    background: #fff;
    padding: 1.5rem 1.25rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

  .fullHeadKyc {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
  }

  .rightBoxKyc h2 {
    color: #242426;
    font-family: Gilroy-Bold;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.03rem;
  }

  .rightBoxKyc span {
    color: #8e8e93;
    font-family: Gilroy-SemiBold;
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0275rem;
  }

  .rightBoxKyc h5 {
    color: #242426;
    font-family: Gilroy-Medium;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
    letter-spacing: 0.0175rem;
  }

  .rightBoxKyc h5 span {
    color: #183fd4;
    cursor: pointer;
    font-family: Gilroy-SemiBold;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
    letter-spacing: 0.0175rem;
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-decoration-skip-ink: none;
    text-decoration-thickness: auto;
    text-underline-offset: auto;
    text-underline-position: from-font;
  }

  .fullHeadKyc h3,
  .halfHeadKyc h3 {
    color: #242426;
    font-family: Gilroy-Medium;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0175rem;
  }

  .fullHeadKyc input {
    width: 100%;
    display: flex;
    padding: 0.9375rem 1rem 0.9375rem 1rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    border-radius: 0.5rem;
    border: 1px solid #d8d8dc;

    font-family: Gilroy-Medium;
    font-size: 0.875rem;
  }

  .halfHeadKyc {
    display: flex;
    width: 100%;
    gap: 1.25rem;
  }

  .halfHeadKyc div {
    width: 48%;
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
  }

  .halfHeadKyc input {
    border-radius: 0.5rem;
    border: 1px solid #d8d8dc;
    display: flex;
    padding: 0.75rem 1rem;
    justify-content: center;
    align-items: center;
  }

  .footerKycfirst {
    width: 100%;
    display: flex;
    flex-direction: column;
    background: #fff;
    padding: 1.125rem 10rem;
    align-items: end;
  }

  .footerKyc {
    width: 100%;
    display: flex;
    justify-content: space-between;
    background: #fff;
    padding: 1.125rem 10rem;
  }

  .footerKycfirst button,
  .footerKyc button:nth-child(2) {
    border-radius: 6.25rem;
    background: #4a6afe;

    display: flex;
    padding: 0.875rem 5rem;
    justify-content: center;
    align-items: center;

    color: #fff;
    font-family: Gilroy-SemiBold;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.02rem;
  }

  .footerKyc button:nth-child(1) {
    border-radius: 6.25rem;
    border: 1px solid #4a6afe;
    display: flex;
    padding: 0.875rem 2.5rem;
    justify-content: center;
    align-items: center;

    color: #242426;
    font-family: Gilroy-SemiBold;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.02rem;
  }

  .uploadIconKyc {
    border-radius: 6.25rem;
    background: #4a6afe;
    display: flex;
    padding: 0.5rem;
    align-items: center;
    justify-content: center;
    width: 2.5rem;
    height: 2.5rem;
  }

  :where(.css-dev-only-do-not-override-m4timi).ant-upload-wrapper
    .ant-upload-list
    .ant-upload-list-item
    .ant-upload-list-item-name {
    margin-top: 0.5rem;
    color: #242426;
    font-family: Gilroy-Medium;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.125rem;
  }

  :where(.css-dev-only-do-not-override-m4timi).ant-upload-wrapper
    [class^="ant-upload"],
  :where(.css-dev-only-do-not-override-m4timi).ant-upload-wrapper
    [class*=" ant-upload"] {
    margin-top: 0.5rem;
  }
}

/* Mobile View */
@media (min-width: 100px) and (max-width: 1023px) {
}
