/* ****************** Laptop View *************************** */

@media (min-width: 1024px) {

  .privacyPolicy {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 60%;
    margin: 0 auto;
    padding: 5rem;
  }

  .privacyPolicy h1 {
    color: #4a6afe;
    font-family: Nunito Sans;
    font-size: 2rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.065rem;
  }

  .privacyPolicy h3 {
    color: #4a6afe;
    font-family: Nunito;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.0225rem;
  }

  .inside-privacypolicy {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 3rem;
    margin-top: 3rem;
  }

  .inside-privacypolicy h2 {
    color: #121212;
    font-family: Nunito Sans;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.03rem;
  }

  .inside-privacypolicy p {
    color: #242426;
    font-family: Nunito Sans;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.0225rem;
  }

  .inside-privacypolicy ul {
    color: #242426;
    font-family: Nunito Sans;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0225rem;
    display: flex;
    gap: 1rem;
    flex-direction: column;
  }

  .inside-privacypolicy li {
    list-style: disc;
  }
}

/* ***********************Mobile Version ************************ */

@media (min-width: 100px) and (max-width: 640px) {
  .privacyPolicy {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 95%;
    margin: 0 auto;
    padding: 5rem;
  }

  .privacyPolicy h1 {
    color: #4a6afe;
    font-family: "Nunito Sans";
    font-size: 3.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.065rem;
  }

  .privacyPolicy h3 {
    color: #4a6afe;
    font-family: "Nunito Sans";
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.0225rem;
  }

  .inside-privacypolicy {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 3rem;
    margin-top: 3rem;
  }

  .inside-privacypolicy h2 {
    color: #121212;
    font-family: Nunito Sans;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.03rem;
  }

  .inside-privacypolicy p {
    color: #242426;
    font-family: "Nunito Sans";
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: 0.0225rem;
  }

  .inside-privacypolicy ul {
    color: #242426;
    font-family: "Nunito Sans";
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    display: flex;
    gap: 1rem;
    flex-direction: column;
    letter-spacing: 0.0225rem;
  }

  .inside-privacypolicy li {
    list-style: disc;
  }
}
