/* / / / ***************** Full Screen ********************* / / / */

@media (min-width: 1024px) {
  .elevate {
    display: flex;
    align-items: center;
    gap: 2.5em;
    padding: 5rem 6.25rem;
  }

  .elevate img {
    width: 50%;
    flex-shrink: 0;
    border-radius: 1.5rem;
  }

  .inner-elevate {
    width: 48%;
    display: flex;
    flex-direction: column;
    gap: 3.25rem;
  }

  .inner-elevate h2 {
    color: #000;
    font-family: Gilroy-SemiBold;
    font-size: 2.625rem;
    font-style: normal;
    font-weight: 400;
    line-height: 3.75rem;
    letter-spacing: 0.0525rem;
  }

  .inner-elevate button {
    padding: 0.9375rem 0.875rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    width: 24%;
    border-radius: 6.25rem;
    background: #4a6afe;

    color: #fff;
    font-family: Gilroy-SemiBold;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.02rem;
  }

  .inner-elevate button:hover{
    background: #3B55CB;
  }
}

/* / / / *****************Mobile View ******************* / / / */

@media (min-width: 100px) and (max-width: 1023px) {
  .elevate {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2.5em;
    padding: 3.75rem 1.25rem;
  }

  .elevate img {
    width: 100%;
    flex-shrink: 0;
    border-radius: 0.75rem;
  }

  .inner-elevate {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2.6rem;
  }

  .inner-elevate h2 {
    color: #000;
    width: 100%;
    text-align: center;
    font-family: Gilroy-SemiBold;
    font-size: 1.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 3.0625rem;
    letter-spacing: 0.035rem;
  }

  .inner-elevate button {
    padding: 1rem 0.875rem;
    justify-content: center;
    align-items: center;
    border-radius: 6.25rem;
    background: #4a6afe;

    color: #fff;
    font-family: Gilroy-SemiBold;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.02rem;
  }
}
