/* / / / ***************** Full Screen ********************* / / / */

@media (min-width: 1024px) {
  .BookaDemoParent {
    display: flex;
    position: relative;
    width: 86%;
    margin: 0 auto;
    padding-top: 2.5rem;
    gap: 2rem;
  }

  .outerDemoParent {
    padding: 2.5rem 0rem;
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  .outerDemoParent h2 {
    color: #242426;
    text-align: center;
    font-family: Gilroy-SemiBold;
    font-size: 2.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.05rem;
  }

  .outerDemoParent h3 {
    color: #242426;
    text-align: center;
    font-family: Gilroy-Medium;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem; 
    letter-spacing: 0.02rem;
    width: 34%;
  }

  .BookaDemoRightWrapper {
    width: 50%;
    position: relative;
  }
  .BookaDemoLeftWrapper {
    width: 50%;
    padding: 1.5rem;
    background: white;
    border-radius: 1.5rem;
    border: 1px solid #d4d4d8;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 1rem;
  }
  .LeftInputsWrapper {
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    display: flex;
  }

  /* PRICING */
  .cardsOfPricingMobile {
    display: none;
  }
}

/* / / / *****************Mobile View ******************* / / / */

@media (min-width: 100px) and (max-width: 1023px) {
  .BookaDemoParent {
    position: relative;
    width: 95%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding-top: 2.5rem;
  }

  .outerDemoParent {
    padding: 2.5rem 1.2rem;
  }

  .outerDemoParent h2 {
    color: #242426;
    text-align: center;
    font-family: Gilroy-SemiBold;
    font-size: 1.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0375rem;
  }

  .outerDemoParent h3 {
    color: #242426;
    text-align: center;
    font-family: Gilroy-Medium;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: 0.02rem;
    margin-top: 1.5rem;
  }

  .BookaDemoRightWrapper {
    width: 100%;
    position: relative;
  }
  .BookaDemoLeftWrapper {
    width: 100%;
    padding: 1.5rem;
    background: white;
    border-radius: 1.5rem;
    border: 1px solid #d4d4d8;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 1rem;
  }
  .LeftInputsWrapper {
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    display: flex;
    flex-direction: column;
  }

  /* PRICING */
  .cardsOfPricing {
    display: none !important;
  }

  .cardsOfPricingMobile {
    display: flex;
    flex-direction: column;
    gap: 1.88rem;
    margin-top: 2.5rem;
  }
}
