/* src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;



* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    /* font-family: Gilroy-SemiBold; */
}

a{
    text-decoration: none;
}

li{
    list-style: none;
}



/* Standard syntax */
@keyframes animationName {

  }
  
  /* Chrome, Safari, Opera, Edge */
  @-webkit-keyframes animationName {
    /* keyframes and styles go here */
  }
  
  /* Firefox */
  @-moz-keyframes animationName {
    /* keyframes and styles go here */
  }
  
  .element {
    animation: animationName 1s infinite; 
    -webkit-animation: animationName 1s infinite; 
    -moz-animation: animationName 1s infinite; 
  }



  @font-face {
    font-family: 'Gilroy-Regular';
    src: url('../gilroy/Gilroy-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'Gilroy-Regular';
    src: url('../gilroy/Gilroy-Regular.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
  }


  @font-face {
    font-family: 'Gilroy-Thin';
    src: url('../gilroy/Gilroy-Regular.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
  }
  
  /* Gilroy SemiBold */
  @font-face {
    font-family: 'Gilroy-Medium';
    src: url('../gilroy/Gilroy-Medium.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: 'Gilroy-SemiBold';
    src: url('../gilroy/Gilroy-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
  }
  
  /* Gilroy Bold */
  @font-face {
    font-family: 'Gilroy-Bold';
    src: url('../gilroy/Gilroy-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
  }
  
  /* Gilroy Medium */
  @font-face {
    font-family: 'Gilroy-Medium';
    src: url('../gilroy/Gilroy-Medium.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: 'Gilroy-ExtraBold';
    src: url('../gilroy/Gilroy-ExtraBold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
  }
  
