/* / / / ***************** Full Screen ********************* / / / */

@media (min-width: 1024px) {
  .newhero {
    background: #000;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="1301" height="900" viewBox="0 0 1301 900" fill="none"><g filter="url(%23filter0_f_833_593)"><path d="M350.603 340.258C350.603 185.991 116.868 106.475 -5.94258e-05 86C202.482 287.145 80.0251 425.672 244.485 487.617C408.945 549.563 866.78 731.161 994.317 1025.6C1121.85 1320.04 994.317 954.199 994.317 807.079C994.317 659.96 784.537 593.647 679.647 578.88C569.966 563.617 350.603 494.525 350.603 340.258Z" fill="%23EBEFFF" fill-opacity="0.25"/></g><defs><filter id="filter0_f_833_593" x="-250" y="-164" width="1551" height="1551" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/><feGaussianBlur stdDeviation="125" result="effect1_foregroundBlur_833_593"/></filter></defs></svg>'),
      url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="1440" height="900" viewBox="0 0 1440 900" fill="none"><g filter="url(%23filter0_f_833_592)"><path d="M348.364 786.397C137 786.397 28.0528 1020.13 0 1137C275.594 934.518 465.392 1056.97 550.265 892.515C635.138 728.055 883.95 270.22 1287.37 142.683C1690.78 15.1463 1189.54 142.683 987.968 142.683C786.396 142.683 695.539 352.463 675.307 457.353C654.395 567.034 559.729 786.397 348.364 786.397Z" fill="%23738CFF" fill-opacity="0.25"/></g><defs><filter id="filter0_f_833_592" x="-250" y="-164" width="1940" height="1551" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/><feGaussianBlur stdDeviation="125" result="effect1_foregroundBlur_833_592"/></filter></defs></svg>');
    background-size: contain, contain;
    background-position: top left, top right;
    background-repeat: no-repeat, no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 3.3rem 6.25rem 0rem 6.25rem;
  }
  
  .upper-hero {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 2.2rem;
  }

  .upper-hero h2 {
    color: #fff;
    font-family: Gilroy-Medium;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.04rem;
  }

  .upper-hero h3 {
    color: #fff;
    text-align: center;
    font-family: Gilroy-SemiBold;
    font-size: 3.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.065rem;
    width: 65%;
  }

  /* .upper-hero h4 {
    color: #fff;
    text-align: center;
    font-family: Caveat;
    font-size: 3.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.065rem;

    position: relative;
    transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
  } */

  .upper-hero h6 {
    color: #fff;
    text-align: center;
    font-family: Gilroy-Medium;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.75rem;
    letter-spacing: 0.025rem;
    width: 65%;
  }

  /* Add this CSS to your stylesheet */

  .upper-hero h4.slide-in {
    transform: translateY(0);
    opacity: 1;
  }

  .upper-hero h4.slide-out {
    transform: translateY(100%);
    opacity: 0;
  }

  .below-hero {
    margin-top: 6rem;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 35rem;
  }

  .hero1 {
    width: 90%;
    height: 100%;
    flex-shrink: 0;
    border-radius: 0.5rem;
  }

  /* .inside-hero {
    gap: 1.25rem;
    display: flex;
    flex-direction: column;
  } */

  /* .hero2,
  .hero5 {
    width: 18.4375rem;
    height: 18.125rem;
    flex-shrink: 0;
    border-radius: 0.5rem;
  }

  .hero3,
  .hero4 {
    width: 18.4375rem;
    height: 15rem;
    flex-shrink: 0;
    border-radius: 0.5rem;
  } */
}

/* / / / *****************Mobile View ******************* / / / */

@media (min-width: 100px) and (max-width: 1023px) {
  .newhero {
    background: #000;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="428" height="800" viewBox="0 0 428 800" fill="none"><g filter="url(%23filter0_f_837_2927)"><path d="M142.777 287.538C142.777 188.314 47.5922 137.169 -5.00679e-06 124C82.4571 253.376 32.5888 342.476 99.5621 382.319C166.535 422.162 352.98 538.965 404.917 728.347C456.854 917.73 404.917 682.423 404.917 587.796C404.917 493.169 319.488 450.517 276.773 441.019C232.108 431.202 142.777 386.762 142.777 287.538Z" fill="%23EBEFFF" fill-opacity="0.25"/></g><defs><filter id="filter0_f_837_2927" x="-250" y="-126" width="928" height="1176" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/><feGaussianBlur stdDeviation="125" result="effect1_foregroundBlur_837_2927"/></filter></defs></svg>'),
    url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="428" height="800" viewBox="0 0 428 800" fill="none"><g filter="url(%23filter0_f_837_2926)"><path d="M103.542 574.493C40.7193 574.493 8.33791 724.831 0 800C81.9127 669.764 138.325 748.528 163.551 642.748C188.777 536.968 262.729 242.489 382.634 160.458C502.539 78.4271 353.558 160.458 293.646 160.458C233.734 160.458 206.73 295.388 200.716 362.853C194.501 433.4 166.364 574.493 103.542 574.493Z" fill="%23738CFF" fill-opacity="0.4"/></g><defs><filter id="filter0_f_837_2926" x="-250" y="-126" width="928" height="1176" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/><feGaussianBlur stdDeviation="125" result="effect1_foregroundBlur_837_2926"/></filter></defs></svg>');
    background-size: contain, contain;
    background-position: top left, top right;
    background-repeat: no-repeat, no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 6rem 1.35rem 0rem 1.35rem;
}


  .upper-hero {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 1.5rem;
  }

  .upper-hero h2 {
    color: #fff;
    font-family: Gilroy-Medium;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.035rem;
  }

  .upper-hero h3 {
    color: #fff;
    text-align: center;
    font-family: Gilroy-SemiBold;
    font-size: 2.625rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0525rem;
  }

  .upper-hero h6 {
    color: #fff;
    text-align: center;
    font-family: Gilroy-Medium;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.75rem;
    letter-spacing: 0.025rem;
  }

  /* 
  .upper-hero h4 {
    color: #fff;
    text-align: center;
    font-family: Caveat;
    font-size: 2.625rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0525rem;

    position: relative;
    transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
  } */

  .upper-hero h4.slide-in {
    transform: translateY(0);
    opacity: 1;
  }

  .upper-hero h4.slide-out {
    transform: translateY(100%);
    opacity: 0;
  }

  .below-hero {
    margin-top: 4rem;
    display: flex;
    flex-direction: column;
    width: 23rem;
    height: 13rem;
  }

  .below-hero img {
    width: 100%;
    height: 100%;
    flex-shrink: 0;
  }

  /* .hero1 {
    border-radius: 0.5rem;
  }

  .inside-hero {
    gap: 1.12rem;
    display: flex;
  } */

  /* .hero2,
  .hero3 {
    width: 47%;
    border-radius: 0.5rem;
  }

  .hero4,
  .hero5 {
    display: none;
  } */
}
