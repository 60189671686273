/************************** Full screen ********************** */
@media (min-width: 1024px) {
  .question-card {
    padding: 7.5rem 6.25rem;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    background: #f4f4f4;
  }
 
 
  .left-div {
    width: 25%;
  }
 
 
  .left-div h2 {
    color: #000;
    font-family: Gilroy-SemiBold;
    font-size: 2.625rem;
    font-style: normal;
    font-weight: 400;
    line-height: 3.5rem;
    letter-spacing: 0.0525rem;
  }
 
 
  .right-div {
    width: 57%;
  }
 
 
  .outsideText {
    color: #000;
    font-family: Gilroy-Medium;
    font-size: 1.3rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.03rem;
  }
 
 
  .insideText {
    color: #000;
    font-family: Gilroy-Regular;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.625rem;
    letter-spacing: 0.0225rem;
  }
 
 
  /* /////// LOGOS /////////////// */
 
 
  .logo-container {
    overflow-x: scroll;
    display: flex;
    justify-content: center;
    align-items: center;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
 
 
  .logo-container::-webkit-scrollbar {
    display: none;
  }
 
 
  .logo-slider {
    display: flex;
    /* gap: 1rem; */
    /* padding: 0.5rem 0; */
  }
 
 
  .logo-item {
    flex: 0 0 auto;
    width: calc(100% / 5.5);
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 2px solid red; */
  }
 
 
  .text-logo {
    color: #222;
    text-align: center;
    font-family: Gilroy-Medium;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.025rem;
  }
  .logo-nasscom {
    width: 10.5rem;
  }
   .logo-wadhwani {
    height: 4rem;
  }
   .logo-ngis {
    height: 4rem;
  }
   .logo-IA {
    height: 3.5rem;
    width: 4rem;
  }
   .logo-startup {
    height: 9rem;
  }
   .logo-stpi {
    height: 4.5rem;
  }
   .logo-moksha {
    height: 4.5rem;
    width: 5.3rem;
    margin-right: 1rem;
  }
   .logo-meity {
    width: 14rem;
  }
   .logo-pontaq {
    height: 5.5rem;
    width: 6rem;
    margin-left: 2rem;
  }
   .logo-pahadi {
    height: 5rem;
  }
   .logo-janjaes {
    height: 5rem;
  }
   .logo-barbar {
    height: 7rem;
    margin-right: 2rem;
  }
  }
 
 
 /******************** Mobile view ************ */
 
 
 @media (min-width: 100px) and (max-width: 1023px) {
  .question-card {
    padding: 6.25rem 1rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 3.75rem;
    background: #f4f4f4;
  }
 
 
  .left-div {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
 
 
  .left-div h2 {
    color: #0f1533;
    font-family: Gilroy-SemiBold;
    font-size: 1.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2.625rem;
    letter-spacing: 0.035rem;
    width: 100%;
  }
 
 
  .right-div {
    width: 100%;
  }
 
 
  .outsideText {
    color: #000;
    font-family: Gilroy-Medium;
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0275rem;
  }
 
 
  .insideText {
    color: #000;
    font-family: Gilroy-Regular;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.4375rem;
    letter-spacing: 0.02rem;
  }
 
 
  /* /////// LOGOS /////////////// */
 
 
  .text-logo {
    color: #222;
    text-align: center;
    font-family: Gilroy-Medium;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.02rem;
  }
 
 
  .logo-container {
    overflow-x: scroll;
    display: flex;
    justify-content: center;
    align-items: center;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
 
 
  .logo-container::-webkit-scrollbar {
    display: none;
  }
 
 
  .logo-slider {
    display: flex;
    gap: 0.5rem;
  }
 
 
  .logo-item {
    flex: 0 0 auto;
    width: calc(100% / 2.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
 
 
  .logo-nasscom {
    width: 9rem;
  }
   .logo-wadhwani {
    height: 3rem;
  }
   .logo-ngis {
    height: 3rem;
  }
   .logo-IA {
    height: 3.5rem;
    width: 5rem;
  }
   .logo-startup {
    height: 9rem;
  }
   .logo-stpi {
    width: 6.5rem;
  }
   .logo-moksha {
    height: 4.5rem;
    width: 5rem;
    margin-right: 2rem;
  }
   .logo-meity {
    height: 3rem;
  }
   .logo-pontaq {
    height: 5.5rem;
    width: 6rem;
    margin-left: 2rem;
  }
   .logo-pahadi {
    height: 4rem;
  }
   .logo-janjaes {
    width: 4rem;
  }
   .logo-barbar {
    height: 6rem;
    margin-right: 2rem;
  }
 }
 
 
 
 
 
 