@media (min-width: 1024px) {
  .marketCategoryWrapper {
    width: 100%;
    display: grid;
    gap: 1.25rem;
    grid-template-columns: repeat(4, 1fr);
    padding-top: 1.25rem;
  }

  .card-list {
    display: grid;
    gap: 1.25rem;
    grid-template-columns: repeat(4, 1fr);
    padding-top: 1.25rem;
  }

  .card {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 0.75rem;
    border: 1px solid #e5e5ea;
  }

  .card > div {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .summer {
    width: 90%;
    height: 52vh;
    margin: 0 auto;
    margin-top: 2rem;
  }

  .summer img {
    border-radius: 2rem;
  }

  .nextGen {
    display: flex;
    width: 90%;
    margin: 3rem auto;
    height: 55vh;
    border-radius: 2rem;
    background: #f4f4f4;
    padding: 3.125rem;
    align-items: center;
    gap: 2.5rem;
  }

  .leftgen {
    width: 48%;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 3rem;
  }

  .leftgen h2 {
    color: #000;
    font-family: Gilroy-SemiBold;
    font-size: 2.625rem;
    font-style: normal;
    font-weight: 400;
    line-height: 4.125rem;
    letter-spacing: 0.0525rem;
  }

  .leftgen button {
    border-radius: 6.25rem;
    background: #4a6afe;

    display: flex;
    padding: 0.8125rem 1rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;

    color: #fff;
    font-family: Gilroy-SemiBold;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.02rem;
  }

  .rightgen {
    width: 48%;
  }

  .rightgen img {
    border-radius: 1.5rem;
  }

  .bussbttn {
    display: flex;
    padding: 0.8125rem 1.25rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    border-radius: 6.25rem;
    background: rgba(237, 240, 255, 0.2);

    color: #fff;
    font-family: Gilroy-SemiBold;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.02rem;
  }

  .custom-place::placeholder {
    color: #242426;
    font-family: 'Gilroy-Regular', sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.02rem;
  }
  
}
