/* Full Screen */
@media (min-width: 1024px) {
  .aboutParent {
    display: flex;
    gap: 2.5rem;
    width: 86%;
    margin: 5rem auto;
    align-items: center;
    justify-content: center;
  }

  .outerAbout h2 {
    color: #242426;
    text-align: center;
    font-family: Gilroy-SemiBold;
    font-size: 1.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2.5rem;
    letter-spacing: 0.0375rem;
    padding-top: 4rem;
  }

  .leftAbout {
    width: 45%;
  }

  .leftAbout img {
    border-radius: 1.5rem;
  }

  .rightAbout {
    width: 48%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  .rightAbout h3 {
    color: #000;
    font-family: Gilroy-SemiBold;
    font-size: 1.625rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0325rem;
  }

  .rightAbout h4 {
    color: #242426;
    font-family: Gilroy-Regular;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.625rem;
    letter-spacing: 0.025rem;
  }

  .ourTeamMobile {
    display: none;
  }

  .ourTeam {
    background: #000;
    padding: 7.5rem;
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .ourTeam h2 {
    color: #fff;
    text-align: center;
    font-family: Gilroy-SemiBold;
    font-size: 2.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.05rem;
    margin-bottom: 6rem;
  }

  .firstLine {
    display: flex;
    align-items: center;
    margin: 0 auto;
    justify-content: space-between;
    /* width: 65%; */
  }

  .firstLine:last-child{
    width: 65%;
  }

  .secondLine {
    display: flex;
    margin: 0 auto;
    justify-content: space-between;
    margin-bottom: 4rem;
  }

  .firstLine > div,
  .secondLine > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .firstLine h4,
  .secondLine h4 {
    color: #fff;
    text-align: center;
    font-family: Gilroy-SemiBold;
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.625rem;
    letter-spacing: 0.0275rem;
    margin-top: 1.25rem;
  }

  .firstLine h5,
  .secondLine h5 {
    color: #c5c5c5;
    font-family: Gilroy-Medium;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.625rem;
    letter-spacing: 0.02rem;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
  }

  .joinUs {
    min-height: 20vh;
    background: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 3.75rem;
    padding: 5rem;
  }

  .joinUs h2 {
    color: #000;
    text-align: center;
    font-family: Gilroy-SemiBold;
    font-size: 2.625rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0525rem;
  }

  .joinUs button {
    display: flex;
    padding: 1rem 1.375rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    border-radius: 6.25rem;
    background: #4a6afe;

    color: #fff;
    font-family: Gilroy-SemiBold;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.02rem;
  }
}

/* Mobile View */
@media (min-width: 100px) and (max-width: 1023px) {
  .aboutParent {
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
    width: 100%;
    margin: 3.75rem auto;
    padding: 0rem 1rem;
    align-items: center;
    justify-content: center;
  }

  .outerAbout h2 {
    color: #242426;
    text-align: center;
    font-family: Gilroy-SemiBold;
    font-size: 1.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2.5rem;
    letter-spacing: 0.0375rem;
    padding-top: 2.4rem;
  }

  .leftAbout {
    width: 98%;
  }

  .leftAbout img {
    border-radius: 1.5rem;
  }

  .rightAbout {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  .rightAbout h3 {
    color: #000;
    font-family: Gilroy-SemiBold;
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0275rem;
  }

  .rightAbout h4 {
    color: #242426;
    font-family: Gilroy-Regular;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.625rem;
    letter-spacing: 0.02rem;
  }

  .joinUs {
    background: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2.62rem;
    padding: 4rem 1.2rem;
  }

  .joinUs h2 {
    color: #000;
    text-align: center;
    font-family: Gilroy-SemiBold;
    font-size: 1.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 3.0625rem;
    letter-spacing: 0.035rem;
  }

  .joinUs button {
    display: flex;
    padding: 0.8125rem 0.875rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    border-radius: 6.25rem;
    background: #4a6afe;

    color: #fff;
    font-family: Gilroy-SemiBold;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.02rem;
  }

  /* /////// Our Team ///////////////// */

  .ourTeam {
    display: none;
  }

  .ourTeamMobile {
    background: #000;
    padding: 3.5rem 1.25rem;
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .ourTeamMobile h2 {
    color: #fff;
    text-align: center;
    font-family: Gilroy-SemiBold;
    font-size: 1.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2.5rem;
    letter-spacing: 0.0375rem;
    margin-bottom: 4rem;
  }

  .firstLine {
    display: flex;
    gap: 1.75rem;
    margin-bottom: 2.5rem;
  }

  .firstLine > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;

  }

  .firstLine h4 {
    color: #fff;
    text-align: center;
    font-family: Gilroy-SemiBold;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.625rem;
    letter-spacing: 0.025rem;
    margin-top: 1.1rem;
  }

  .firstLine h5 {
    color: #c5c5c5;
    text-align: center;
    font-family: Gilroy-Medium;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.625rem;
    letter-spacing: 0.0175rem;
  }
}
