/* / / / ***************** Full Screen ********************* / / / */


@media (min-width: 1024px) {
  .mobile-simplify {
    display: none;
  }
 
 
  .simplify {
    display: block;
  }
 }
 
 
 /* / / / *****************Mobile View ******************* / / / */
 
 
 @media (min-width: 100px) and (max-width: 1023px) {
  .mobile-simplify {
    display: block;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 6.25rem 1.25rem;
    gap: 3.75rem;
    background: #edf0ff;
  }
 
 
  .mobile-simplify h2 {
    text-align: center;
    color: #0f1533;
    width: 100%;
    font-family: Gilroy-SemiBold;
    font-size: 1.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2.625rem;
    letter-spacing: 0.035rem;
  }
 
 
  .mobile-simplify span {
    color: #0f1533;
    font-family: Caveat;
    font-size: 2.625rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2.625rem;
    letter-spacing: 0.0525rem;
  }
 
 
  .simplify {
    display: none;
  }
 
 
  .inside-simplify {
    border-radius: 0.75rem;
    width: 98.5%;
    margin: 0 auto;
  }
 
 
  .inside-simplify img {
    width: 24.25rem;
    height: 15rem;
    border-radius: 0.75rem 0.75rem 0rem 0rem;
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.1) 0%,
        rgba(0, 0, 0, 0.3) 100%
      ),
      lightgray 0px -80.964px / 100% 242.47% no-repeat;
  }
 
 
  .below-simplify {
    display: flex;
    background-color: black;
    padding: 1.5rem;
    flex-direction: column;
    gap: 2rem;
    border-radius: 0rem 0rem 0.75rem 0.75rem;
  }
 
 
  .below-simplify h3 {
    color: #fff;
    font-family: Caveat;
    font-size: 2.375rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.0475rem;
  }
  .below-simplify h4 {
    color: #fff;
    font-family: Gilroy-Regular;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.75rem;
    letter-spacing: 0.0275rem;
  }
 
 
  /* Custom styles for the carousel dots */
  .carousel-dots li button {
    background: #c0c0c0;
  }
 
 
  .carousel-dots li.slick-active button {
    background: #0f1533;
  }
 
 
  .dots {
    display: flex;
    justify-content: center;
  }
 
 
  .dot {
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 3.125rem;
    background: #cad1f9;
    margin: 0 5px;
    border: none;
    cursor: pointer;
  }
 
 
  .dot.active {
    width: 1.625rem;
    height: 0.5rem;
    border-radius: 3.125rem;
    background: #4f65eb;
  }
 }
 
 
 
 
 
 